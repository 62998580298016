<template>
  <div>
    <!-- Título principal -->
    <div class="d-flex align-center justify-space-between mb-10 my-8">
      <v-btn icon rounded @click="$router.go(-1)">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dense v-bind="attrs" v-on="on">
              <v-icon size="28">mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>Volver</span>
        </v-tooltip>
      </v-btn>
      <h1 class="justify-center">Ingreso de visitas</h1>
      <v-btn
        color="primary rounded-lg text-capitalize"
        @click="activeCreateVisit = true"
        >Ingresar
      </v-btn>
    </div>
    <!-- Información respecto al uso de la página -->
    <v-alert
      colored-border
      type="info"
      color="primary"
      elevation="2"
      class="my-8"
    >
      Aquí puedes ver las visitas de atención del día en curso y crear nuevas,
      además puedes filtrar por otros días.
      <br />
      <v-avatar size="20">
        <v-icon color="green">mdi-bookmark-check-outline</v-icon>
      </v-avatar>
      Indica que la atención se encuentra en curso o que se realizo alguna
      actulización de datos sobre la atención.
    </v-alert>
    <!-- content-atenciondia -->
    <div v-if="licen.Var7 == 1">
      <!-- Crear nueva visita (Despliegue al pinchar "Ingresar") -->
      <v-row v-if="activeCreateVisit" class="mt-2">
        <!-- Búsqueda de cliente -->
        <v-col cols="12" xs="12" sm="12" md="12" lg="7" xl="7">
          <v-card width="100%" class="pa-3 mb-5 rounded-xl">
            <v-card-title class="mt-4 ml-10">
              <h2>Buscar cliente</h2>
            </v-card-title>
            <div class="pa-3">
              <div class="d-flex">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  placeholder="Para buscar por nombre, rut o ficha, debe presionar enter o presionar el boton buscar al final de esté campo"
                  @keyup.enter="btnSearchCliente"
                  hide-details
                  class="mb-1"
                ></v-text-field>
                <v-btn
                  color="primary"
                  outlined
                  class="mt-2 ml-2"
                  @click="btnSearchCliente"
                  >Buscar</v-btn
                >
              </div>
              <v-data-table
                :headers="headersClientes"
                :items="clientes"
                :search="search"
                :loading="loadingClientes"
                loading-text="Buscando... Espere"
                class="mt-2"
                dense
                :footer-props="{
                  'items-per-page-text': 'Filas por página',
                }"
              >
                <template v-slot:item.action="{ item }">
                  <div class="actions pa-1">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="text-capitalize rounded-lg"
                          @click="btnSelectCustomerDialog(item.Id)"
                          ><v-icon>mdi-check-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Seleccionar</span>
                    </v-tooltip>
                  </div>
                </template>
                <template v-slot:no-data>
                  <v-alert :value="true">
                    No hay resultados disponibles
                  </v-alert>
                </template>
              </v-data-table>
            </div>
          </v-card>
          <!-- Diálogo desplegado al pinchar "seleccionar" -->
          <v-dialog
            v-model="dialogSelect"
            max-width="650px"
            persistent
            class="pa-3 mb-5 rounded-xl"
          >
            <v-card width="100%" class="rounded-xl">
              <div class="pa-3">
                <v-card-title
                  >¿Seguro(a) que quiere crear
                  <br class="d-flex d-sm-flex d-md-none d-lg-none d-xl-none" />
                  una nueva visita a la mascota
                  <br class="d-flex d-sm-flex d-md-none d-lg-none d-xl-none" />
                  <v-chip outlined color="primary" class="ma-1"
                    >{{ cliente.nombre }}
                  </v-chip>
                  del cliente:
                  <br class="d-flex d-sm-flex d-md-none d-lg-none d-xl-none" />
                  {{ cliente.amo }}
                  <br class="d-flex d-sm-flex d-md-none d-lg-none d-xl-none" />
                  rut {{ cliente.rut }} ?
                </v-card-title>
                <v-card-actions class="d-flex justify-end">
                  <v-btn
                    color="primary"
                    outlined
                    @click="closeDelete"
                    class="text-capitalize rounded-lg"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="primary"
                    @click="btnSelectCustomer()"
                    class="text-capitalize rounded-lg ml-3"
                    >Confirmar</v-btn
                  >
                </v-card-actions>
              </div>
            </v-card>
          </v-dialog>
        </v-col>
        <!-- Datos de la mascota y cliente seleccionado -->
        <v-col cols="12" xs="12" sm="12" md="12" lg="5" xl="5">
          <div>
            <div>
              <v-text-field
                outlined
                readonly
                dense
                label="Número de ficha"
                :value="cliente.fichan"
                class="ma-2"
              >
              </v-text-field>
            </div>
            <div
              class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
            >
              <v-text-field
                class="ma-2"
                outlined
                dense
                readonly
                label="Nombre mascota"
                :value="cliente.nombre"
              ></v-text-field>
              <v-text-field
                class="ma-2"
                outlined
                dense
                label="Propietario"
                readonly
                :value="cliente.amo"
              ></v-text-field>
            </div>
            <div
              class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
            >
              <v-text-field
                class="ma-2"
                outlined
                dense
                label="Edad"
                readonly
                :value="ageCalculate(cliente.nacimiento)"
              ></v-text-field>
              <v-text-field
                class="ma-2"
                outlined
                label="Fecha de nacimiento"
                dense
                readonly
                :value="setDateNacimiento(cliente.nacimiento)"
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                class="ma-2"
                outlined
                label="Plan de salud"
                dense
                readonly
                :value="cliente.edad"
              ></v-text-field>
            </div>
            <v-divider class="ml-2 mr-2 my-5"></v-divider>
            <!-- Lista despegable de selección de doctor y motivo visita -->
            <div
              class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
            >
              <div v-if="licen.Var37 == 1">
                <v-autocomplete
                  v-model="selectDoctorSupervisor"
                  :items="doctors"
                  item-text="Nombre"
                  return-object
                  outlined
                  dense
                  label="Profesional"
                  class="ma-2"
                ></v-autocomplete>
              </div>
              <div v-else>
                <v-text-field
                  class="ma-2"
                  v-model="doctor.Nombre"
                  outlined
                  label="Profesional"
                  dense
                  readonly
                ></v-text-field>
              </div>
              <v-autocomplete
                v-model="atenciondia.Pago"
                :items="motivovisitas"
                item-text="Descripcion"
                outlined
                color="primary"
                dense
                label="Motivo de visita"
                class="ma-2"
              ></v-autocomplete>
            </div>
            <!-- Botones de ingresar visita, limpiar y replegar -->
            <div class="d-flex justify-end mt-3">
              <v-btn
                :loading="loadPostCreateAtenciondia"
                :disabled="
                  !atenciondia.Pago ||
                  !cliente.nombre ||
                  loadPostCreateAtenciondia
                "
                color="primary"
                class="ma-1 text-capitalize rounded-lg"
                @click="btnCreateAtenciondia"
                >Ingresar visita</v-btn
              >
              <v-btn
                outlined
                color="primary"
                @click="btnCleanFormCliente"
                class="ma-1 text-capitalize rounded-lg"
                >Limpiar</v-btn
              >
              <v-btn
                outlined
                color="primary"
                @click="activeCreateVisit = false"
                class="mx-2 ma-1 text-capitalize rounded-lg"
                >Cerrar</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
      <!--  Tabla de visitas del día -->
      <v-row class="">
        <v-card
          width="100%"
          class="ma-4 mb-5 rounded-xl"
          :loading="activeLoadingSearchAtenciondias"
        >
          <!-- Título -->
          <div class="pa-3">
            <v-card-title class="mt-4">
              <h2 class="ml-10 mb-5">Visitas del día</h2>
              <v-spacer></v-spacer>
              <div class="d-flex justify-lg-space-between justify-sm-start">
                <v-menu
                  v-model="menuDateFilterAtencion"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  left
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFilterAtencion"
                      label="Filtrar por fecha"
                      prepend-icon="mdi-calendar"
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFilterAtencion"
                    class="d-flex ma-auto"
                  >
                    <v-row class="d-flex justify-end pa-2">
                      <v-btn
                        text
                        color="primary"
                        @click="btnFilterDateAtenciondia"
                        class="text-capitalize"
                      >
                        Filtrar
                      </v-btn>
                    </v-row>
                  </v-date-picker>
                </v-menu>
              </div>
            </v-card-title>
            <!-- Información de la tarjeta -->
            <div class="pa-3">
              <!-- Buscador -->
              <v-text-field
                class="mb-1"
                v-model="searchAtenciondia"
                append-icon="mdi-magnify"
                color="primary"
                single-line
                autofocus
                hide-details
              ></v-text-field>
              <!-- tabla -->
              <v-data-table
                :headers="headers"
                :items="atenciondias"
                :search="searchAtenciondia"
                dense
                :footer-props="{
                  'items-per-page-text': 'Filas por página',
                }"
              >
                <!-- Hora -->
                <template v-slot:item.Hora="{ item }">
                  <div width="100%">
                    {{
                      setDateString(
                        item.Fecha.substring(0, 10) +
                          "T" +
                          item.Hora.substring(13, 17)
                      )
                    }}
                  </div>
                </template>
                <!-- Mascota (nombre) -->
                <template v-slot:item.Nombre="{ item }">
                  <div class="d-flex align-center">
                    <h3 class="my-3 primary--text">{{ item.Nombre }}</h3>
                    <v-avatar
                      v-if="item.Monto == '&H80FF80'"
                      class="ml-3"
                      size="20"
                    >
                      <v-icon color="green">mdi-bookmark-check-outline</v-icon>
                    </v-avatar>
                  </div>
                </template>
                <!-- Motivo -->
                <template v-slot:item.Pago="{ item }">
                  <v-chip dense color="primary" outlined class="my-3">{{
                    item.Pago
                  }}</v-chip>
                </template>
                <!-- Acción -->
                <template v-slot:item.action="{ item }">
                  <div class="actions">
                    <!-- Eliminar visita -->
                    <v-btn
                      icon
                      @click="btnDeleteAtencion(item.Fichan)"
                      class="mr-4"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                    <!-- Ver visita (redirige a vista Ficha médica ) -->
                    <v-btn icon @click="btnSelectFichaCliente(item.Fichan)">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                </template>
                <!-- Mensaje en caso de no haber datos para mostrar -->
                <template v-slot:no-data>
                  <v-alert :value="true">
                    No hay resultados disponibles
                  </v-alert>
                </template>
              </v-data-table>
              <!-- dialogo de eliminacion -->
              <v-dialog
                v-model="dialogDelete"
                max-width="650px"
                persistent
                class="pa-3 mb-5 rounded-xl"
              >
                <v-card width="100%" class="rounded-xl">
                  <div class="pa-3">
                    <v-card-title v-if="successDeleteAtencion"
                      >¿Seguro(a) que quiere quiere eliminar la atencion?
                    </v-card-title>
                    <v-card-title v-else
                      >No es posible eliminar la atención, ya esta en curso.
                      <br />
                      Contacte a su supervisor para realizar está acción
                    </v-card-title>
                    <v-card-actions class="d-flex justify-end">
                      <v-btn
                        color="primary"
                        outlined
                        @click="dialogDelete = false"
                        class="text-capitalize rounded-lg"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        v-if="successDeleteAtencion"
                        color="primary"
                        :loading="activeUploadingDeleteAtenciondia"
                        @click="btnConfirmDeleteAtenciondia"
                        class="text-capitalize rounded-lg ml-3"
                        >Eliminar</v-btn
                      >
                    </v-card-actions>
                  </div>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </v-card>
      </v-row>
      <!-- Mensaje en caso de que la atención se haya creado -->
      <v-snackbar color="primary" v-model="successCreateAtenciondia">
        Atención creada con éxito
      </v-snackbar>
      <v-snackbar color="primary" v-model="successDeleteAtenciondiaById">
        Atención eliminada con éxito
      </v-snackbar>
    </div>
    <div v-else class="d-flex justify-center pa-3 ma-3">
      <div class="d-flex flex-column">
        <v-avatar tile size="300" class="d-flex justify-center">
          <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
        </v-avatar>
        <div class="d-flex justify-center">
          <h3 class="mt-3 ml-3">
            No los permisos necesarios para <br />
            acceder a los datos
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      headersClientes: [
        { text: "Ficha", value: "Fichan" },
        { text: "Mascota", value: "Nombre" },
        { text: "Nombre", value: "Amo" },
        { text: "Rut", value: "Rut" },
        { text: "Teléfono", value: "Fono" },
        { text: "", value: "action" },
      ],
      headers: [
        {
          text: " Ficha",
          value: "Fichan",
        },
        {
          text: "Mascota",
          align: "start",
          sortable: false,
          value: "Nombre",
        },
        { text: "Fecha", value: "Hora" },
        { text: "Motivo", value: "Pago" },
        { text: "Profesional", value: "Nombredoc" },
        { text: "", value: "action" },
      ],
      search: "",
      searchAtenciondia: "",
      searchCustomer: "",
      headersCustomers: [
        {
          text: "id",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Nombre", value: "name" },
        { text: "Rut", value: "customer_id" },
        { text: "Teléfono", value: "mobile_phone" },
        { text: "", value: "action" },
      ],
      cliente: {
        fichan: 0,
        nombre: "",
        amo: "",
        fono: "",
        nacimiento: "",
        raza: "",
        direccion: "",
        comuna: "",
        ciudad: "",
        email: "",
        sexo: "",
        fotoactiva: "SI",
        madre: "El Bosque",
        padre: "No Registra",
        peso: "No Registra",
        altura: "",
        edad: "",
        reg: "",
        color: "",
        esp: "",
        insc: "0",
        microchip: "",
        fichaant: "",
        clinica: "",
        drtratante: "Cliente vigente",
        peluqueria: "no registra",
        rut: "",
        id: 0,
      },

      //dialogo de eliminación de atención
      dialogDelete: false,
      successDeleteAtencion: false,

      atenciondia: {
        Rut: "",
        Dig: "",
        Nombre: "",
        Fichan: 0,
        Fechahora: "",
        Hora: "",
        Pago: "",
        Bonon: "",
        Monto: "",
        Informe: "",
        Reg: "",
        Rutdoc: "",
        Nombredoc: "",
        Fecha: "",
        Sucursal: "",
        Tipo: "",
      },
      selectCustomerId: "",
      dialogSelect: false,
      selectDoctor: "",
      activeCreateVisit: false,
      page: 1,
      selectCustomerId: "",
      selectCustomer: false,
      dialogSelect: false,
      activeSearchCustomers: false,
      menuDateFilterAtencion: false,
      dateFilterAtencion: "",
      loadPostCreateAtenciondia: false,
      idDeleteAtenciondia: "",
      activeUploadingDeleteAtenciondia: false,
      selectDoctorSupervisor:""
    };
  },
  watch: {
    dialogSelect(val) {
      val || this.closeDelete();
    },
    successCreateAtenciondia: function (val) {
      if (val) {
        this.btnCleanFormCliente();
        this.activeCreateVisit = false;
        this.loadPostCreateAtenciondia = false;
      }
    },
    successDeleteAtenciondiaById: function (val) {
      if (val) {
        this.activeUploadingDeleteAtenciondia = false;
        this.dialogDelete = false;
      }
    },
    selectDoctorSupervisor: function(val){
      if(val.Id!=0){
        this.selectDoctorSupervisor = val
      }
    }
  },
  methods: {
    ...mapActions("customers-module", [
      "postCliente",
      "getClientes",
      "selectFichaCliente",
    ]),
    ...mapActions("medical-module", [
      "postMedicalCareVisit",
      "postAtenciondia",
      "getAtenciondias",
      "deleteAtenciondiaById",
    ]),
    setDateString(date) {
      var dataSet = new Date(date.substring(0, 10) + "T00:00:00");
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year;
    },
    btnSearchCliente() {
      this.getClientes(this.search);
    },
    //eliminación de atencion
    btnDeleteAtencion(fichan) {
      let atencion = this.atenciondias.find((item) => item.Fichan == fichan);
      if (atencion.Monto != "&H80FF80") {
        this.successDeleteAtencion = true;
        this.idDeleteAtenciondia = atencion.Id;
      } else {
        this.successDeleteAtencion = false;
      }
      this.dialogDelete = true;
    },
    btnConfirmDeleteAtenciondia() {
      this.activeUploadingDeleteAtenciondia = true;
      this.deleteAtenciondiaById({ id: this.idDeleteAtenciondia });
      this.idDeleteAtenciondia = "";
    },
    closeDelete() {
      this.dialogSelect = false;
    },
    btnSelectCustomerDialog(id) {
      this.selectCustomerId = id;
      let search = this.clientes.find(
        (item) => item.Id == this.selectCustomerId
      );
      this.cliente.nombre = search.Nombre;
      this.cliente.amo = search.Amo;
      this.cliente.rut = search.Rut;

      this.dialogSelect = true;
    },
    btnSelectCustomer() {
      let search = this.clientes.find(
        (item) => item.Id == this.selectCustomerId
      );
      this.cliente.fichan = search.Fichan;
      this.cliente.fono = search.Fono;
      this.cliente.raza = search.Raza;
      this.cliente.nombre = search.Nombre;
      this.cliente.amo = search.Amo;
      this.cliente.nacimiento = search.Nacimiento;
      this.cliente.direccion = search.Direccion;
      this.cliente.comuna = search.Comuna;
      this.cliente.ciudad = search.Ciudad;
      this.cliente.email = search.Email;
      this.cliente.fotoactiva = search.Fotoactiva;
      this.cliente.padre = search.Padre;
      this.cliente.peso = search.Peso;
      this.cliente.altura = search.Altura;
      this.cliente.edad = search.Edad;
      this.cliente.esp = search.Esp;
      this.cliente.fichaant = search.Fichaant;
      this.cliente.clinica = search.Clinica;
      this.cliente.drtratante = search.drtratante;
      this.cliente.peluqueria = search.Peluqueria;
      this.cliente.microchip = search.Microchip;
      this.cliente.reg = search.Reg;
      this.cliente.insc = search.Insc;
      this.cliente.rut = search.Rut;

      this.dialogSelect = false;
      this.selectCustomerId = "";
      this.selectCustomer = true;
      this.activeSearchCustomers = false;
    },
    searchClientes() {
      this.getClientes(this.search);
    },
    getAge(dateString) {
      var now = new Date();
      var today = new Date(now.getYear(), now.getMonth(), now.getDate());

      var yearNow = now.getYear();
      var monthNow = now.getMonth();
      var dateNow = now.getDate();

      var dob = new Date(
        dateString.substring(0, 4),
        dateString.substring(5, 7) - 1,
        dateString.substring(8, 10)
      );

      var yearDob = dob.getYear();
      var monthDob = dob.getMonth();
      var dateDob = dob.getDate();
      var age = {};
      var ageString = "";
      var yearString = "";
      var monthString = "";
      var dayString = "";

      let yearAge = yearNow - yearDob;

      if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
      else {
        yearAge--;
        var monthAge = 12 + monthNow - monthDob;
      }

      if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
      else {
        monthAge--;
        var dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }

      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge,
      };

      if (age.years > 1) yearString = " años";
      else yearString = " año";
      if (age.months > 1) monthString = " meses";
      else monthString = " mes";
      if (age.days > 1) dayString = " días";
      else dayString = " día";

      if (age.years > 0 && age.months > 0 && age.days > 0)
        ageString =
          age.years +
          yearString +
          ", " +
          age.months +
          monthString +
          ", y " +
          age.days +
          dayString;
      else if (age.years == 0 && age.months == 0 && age.days > 0)
        ageString = "solo " + age.days + dayString;
      else if (age.years > 0 && age.months == 0 && age.days == 0)
        ageString = age.years + yearString + " Feliz cumpleaños!!";
      else if (age.years > 0 && age.months > 0 && age.days == 0)
        ageString = age.years + yearString + " y " + age.months + monthString;
      else if (age.years == 0 && age.months > 0 && age.days > 0)
        ageString =
          age.months + monthString + " y " + age.days + dayString + ".";
      else if (age.years > 0 && age.months == 0 && age.days > 0)
        ageString = age.years + yearString + " y " + age.days + dayString + ".";
      else if (age.years == 0 && age.months > 0 && age.days == 0)
        ageString = age.months + monthString + " .";
      else ageString = "Oops! No es posible calcular!";

      return ageString;
    },
    ageCalculate(birth_date) {
      if (this.cliente.nacimiento) {
        return this.getAge(birth_date);
      }
    },
    setDateNacimiento(nacimiento) {
      return nacimiento.substring(0, 10);
    },
    setDateNow() {
      var currentDateObj = new Date();
      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 60 * 180000;
      var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
      return newDateObj;
    },
    btnCreateAtenciondia() {
      let searchfichan = null
      searchfichan = this.atenciondias.findIndex(item => item.Fichan == this.cliente.fichan)
      if(searchfichan != -1){
        alert("Cliente ya tiene una visita hoy")
        return
      }
      let today = this.setDateNow();
      this.loadPostCreateAtenciondia = true;
      let length_rut = this.cliente.rut.length;
      this.atenciondia.Rut = this.cliente.rut.substring(0, length_rut - 2);
      this.atenciondia.Dig = this.cliente.rut.slice(-1);
      this.atenciondia.Nombre = this.cliente.nombre;
      this.atenciondia.Fichan = this.cliente.fichan;
      this.atenciondia.Fechahora = today.toISOString().substring(0, 10);
      this.atenciondia.Hora =
        today.toISOString().substring(0, 10) +
        " " +
        today.toISOString().substring(11, 19);
      this.atenciondia.Bonon = "0";
      this.atenciondia.Monto = "0";
      this.atenciondia.Informe = ".";
      this.atenciondia.Reg = "0";
      if(this.doctor.Rut){
        this.atenciondia.Rutdoc = this.doctor.Rut;
      }else{
        this.atenciondia.Rutdoc = this.selectDoctorSupervisor.Rut
      }
      if(this.doctor.Nombre){
        this.atenciondia.Nombredoc = this.doctor.Nombre;
      }else{
        this.atenciondia.Nombredoc = this.selectDoctorSupervisor.Nombre
      }
      this.atenciondia.Fecha = today.toISOString().substring(0, 10);
      this.atenciondia.Sucursal = this.user.Sucursal;
      this.atenciondia.Tipo = "V";
      this.postAtenciondia({ atenciondia: this.atenciondia });
    },
    btnFilterDateAtenciondia() {
      this.menuDateFilterAtencion = false;
      this.getAtenciondias({
        fecha: this.dateFilterAtencion,
        sucursal: this.user.Sucursal,
      });
    },
    btnSelectFichaCliente(id) {
      this.selectFichaCliente(id);
      setTimeout(() => {
        this.$router.push("/medical/records");
      }, 400);
    },
    //boton limpiar formulario

    btnCleanFormCliente() {
      this.cliente = {
        fichan: 0,
        nombre: "",
        amo: "",
        fono: "",
        nacimiento: "",
        raza: "",
        direccion: "",
        comuna: "",
        ciudad: "",
        email: "",
        sexo: "",
        fotoactiva: "SI",
        madre: "",
        padre: "No Registra",
        peso: "No Registra",
        altura: "",
        edad: "",
        reg: "",
        color: "",
        esp: "",
        insc: "0",
        microchip: "",
        fichaant: "",
        clinica: "",
        drtratante: "Cliente vigente",
        peluqueria: "no registra",
        rut: "",
        id: 0,
      };
      this.atenciondia.Pago = "";
      this.selectDoctor = "";
    },
    setColorPago(atencion) {
      if (atencion == "&H80FF80") {
        return "green";
      } else {
        return "primary";
      }
    },
  },
  computed: {
    ...mapState("medical-module", [
      "medicalCareVisits",
      "doctors",
      "doctor",
      "motivovisitas",
      "atenciondias",
      "successCreateAtenciondia",
      "successDeleteAtenciondiaById",
      "activeLoadingSearchAtenciondias",
    ]),
    ...mapState("auth-module", ["user", "licen"]),
    ...mapState("pets-module", ["pets"]),
    ...mapGetters("pets-module", ["getPetNameById", "getPetById"]),
    ...mapState("customers-module", [
      "clientes",
      "razas",
      "especies",
      "loadingClientes",
      "successCreateCliente",
    ]),
    ...mapGetters("customers-module", [
      "getCustomerNameById",
      "getCustomerById",
    ]),
  },
};
</script>
<style>
/* Eliminar select Sort By desplegado en vista móvil de tabla  */
.v-data-table-header-mobile__wrapper {
  display: none;
  border-style: none;
}

.v-data-table-header-mobile {
  display: none;
}

/* Eliminar separación entre input y calendario */
.v-text-field__details {
  display: none;
}
/* Título */

</style>
